import './App.css';
import Container from './componenents/Container'

function App() {
  return (
    <div id="app" className="App">
      <Container/>
    </div>
  );
}

export default App;
